.form {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.description {
    padding-bottom: 4px;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--text-secondary);

    overflow: hidden;
    text-overflow: ellipsis;
}
.description b {
    font-weight: 500;
    color: #36394A;
}

.form_panel {
    align-self: flex-start;
    width: 488px;
    padding: 8px;

    border-radius: 16px;
    border: 1px solid var(--global-border-color);
    background: #FFF;
    box-shadow: 0px 16px 32px -12px rgba(14, 18, 27, 0.02);
    margin: 50px auto;
}

.oauth {
    display: flex;
    flex-direction: column;
}
.separator {
    color: #9E9DA4;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 20px 0 24px;
    position: relative;
    user-select: none;
}
.separator span {
    position: relative;
    z-index: 2;
    background: #fff;
    padding: 0 8px;
}
.separator::after {
    content: "";
    position: absolute;
    bottom: 9px;
    left: 0;
    right: 0;
    border-bottom: 1px solid var(--global-border-color);
}

.buttons_stack {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.field_form_error,
.field_error {
    color: #FF5C5C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.field_error {
    margin-top: 8px;
}