.features {
    display: flex;
    gap: 24px;
    margin: 0 auto 127px;
    max-width: 1128px;
    width: 100%;
}
.feature {
    padding: 210px 20px 20px;
    border-radius: 16px;
    background: #fafbff url('../../assets/landing/feature-01.svg') 50% 0 no-repeat;
}
.feature_2 {
    background-image: url('../../assets/landing/feature-03.svg');
}
.feature_3 {
    background-image: url('../../assets/landing/feature-02.svg');
}
.feature h3 {
    font-family: var(--landing-heading-font-family);
    font-weight: normal;
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    color: #36394a;
    margin-bottom: 4px;
}
.feature p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #676a7b;
    max-width: 350px;
}

@media (max-width: 1000px) {
    .features {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 40px;
        padding: 0 8px;
    }
}