.logo {
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    color: #33363e;
    letter-spacing: -0.5px;
    cursor: pointer;
    text-decoration: none;
}
.logo:hover {
    color: #33363e;
}
.logo_small {
    display: none;
}

@media (max-width: 1000px) {
    .logo .logo_small {
        display: block;
    }
    .logo .logo_text {
        display: none;
    }
}