.content_bg {
    background-image: url('../assets/giveaway-bg.png'), url('../assets/grid.svg') !important;
    background-repeat: no-repeat, no-repeat !important;
    background-position: 50% 0, 50% 105px !important;
}
.giveaway_container {
    max-width: 488px;
    width: 100%;
    margin: 0 auto;
    padding: 286px 0 41px;
}

/* Await header */
.await_header {
    margin-bottom: 37px;
}
.await_header h2 {
    color: #36394A;
    text-align: center;
    font-family: "Roobert";
    font-size: 48px;
    font-style: normal;
    font-weight: normal;
    line-height: 60px; /* 125% */
    letter-spacing: 1.92px;
}
.await_header h3 {
    color: rgba(110, 112, 118, 0.72);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 2.8px;
    text-transform: uppercase;
}

/* Await container */
.await_container.tasks_container {
    margin-bottom: 105px;
    gap: 0;
}
.await_container > h2 {
    position: relative;
    padding-bottom: 16px;
    margin-top: -8px;
    margin-bottom: 24px;
    color: rgba(110, 112, 118, 0.72);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 2.8px;
    text-transform: uppercase;
}
.await_container > h2 span {
    color: #4E75FF;
}
.await_container > h2::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, rgba(232, 235, 240, 0.00) 0%, #E8EBF0 49.5%, rgba(232, 235, 240, 0.00) 100%);
}
.await_container.tasks_container > footer {
    display: flex;
    align-items: center;
}
.await_invited_users {
    margin-left: auto;
    display: flex;
    height: 32px;
}
.await_invited_users img {
    border-radius: 100%;
}
.await_invited_users img:not(:last-child) {
    margin-right: -8px;
}
.await_description {
    margin-bottom: 24px;
    color: #36394A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.await_copy_field_label {
    margin-bottom: 4px;
    color: #6E7076;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.await_share_buttons {
    margin-top: 12px;
    margin-bottom: 16px;
    display: flex;
    gap: 8px;
}
.await_share_buttons:not(.await_share_buttons__column) > * {
    flex: 1 1 0px;
}
.await_share_buttons.await_share_buttons__column {
    flex-direction: column;
    margin: 0;
}

.await_footer_count {
    color: #6E7076;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.await_footer_count b {
    color: #36394A;
    font-weight: 500;
}

/* Giveaway header */
.giveaway_header {
    position: relative;
    padding: 40px 0 24px;
    text-align: center;
}
.giveaway_header::before {
    content: "";
    position: absolute;
    top: 0;
    left: -320px;
    right: -320px;
    height: 1px;
    background: linear-gradient(90deg, rgba(232, 235, 240, 0.00) 0%, #E8EBF0 49.5%, rgba(232, 235, 240, 0.00) 100%);
}

@media (max-width: 1150px) {
    .giveaway_header::before {
        left: 0;
        right: 0;
    }
}

.giveaway_header h2 {
    margin-bottom: 4px;

    color: #36394A;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
}
.giveaway_header h3 {
    color: #6E7076;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.giveaway_header span {
    color: #4E75FF;
}


/* Tasks */
.tasks_container {
    margin-bottom: 25px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    gap: 18px;
    flex-shrink: 0;

    border-radius: 12px;
    border: 1px solid #E8EBF0;
    background: #FFF;
    box-shadow: -48px 160px 47px 0px rgba(225, 239, 254, 0.00), -31px 102px 43px 0px rgba(225, 239, 254, 0.01), -17px 58px 36px 0px rgba(225, 239, 254, 0.05), -8px 26px 27px 0px rgba(225, 239, 254, 0.09), -2px 6px 15px 0px rgba(225, 239, 254, 0.10);
}
.tasks {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.task {
    padding: 16px;

    border-radius: 6px;
    border: 1px solid rgba(80, 118, 254, 0.08);
    background: rgba(80, 118, 254, 0.04);
}
.task_header > header {
    display: flex;
    gap: 8px;
    margin-bottom: 7px;
}
.task_heading {
    display: flex;
    align-items: center;
    gap: 8px;
}
.task_title {
    color: #36394A;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}
.task_description {
    margin-bottom: 20px;

    color: rgba(54, 57, 74, 0.64);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.task_ticket {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 8px;
    margin-left: auto;

    color: #36394A;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 1.44px;

    border-radius: 4px;
    background: #FFF;
}
.task_number {
    display: inline-flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;

    border-radius: var(--radius-4, 4px);
    background: #FFF;

    color: #36394A;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
}
.task_socials {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}
.social_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    align-items: center;
    gap: 6px;
    transition: box-shadow .3s, background .3s;

    border-radius: 6px;
    background: #FFF;

    cursor: pointer;

    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border: none;
}
.social_button:not(:disabled):hover {
    box-shadow: 0 0 10px #3285e51c;
}
.social_button:not(:disabled):active {
    background: #eee;
}
.social_button:disabled {
    opacity: 0.4;
    cursor: default;
}
.social_done {
    opacity: 0.4;
    text-decoration: line-through;
}
.task_done {
    padding-top: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: #36394A;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.task_share {
    display: flex;
    gap: 8px;
}
.task_share .copy_field {
    flex-grow: 1;
}

/* Tickets */
.tickets {
    display: flex;
}
.tickets .join_button {
    margin-left: auto;
    max-width: 237px;
    width: 100%;
}
.ticket_stats {
    display: flex;
    flex-direction: column;
    gap: 2px;

    color: #6E7076;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.ticket_stats_count {
    display: flex;
    gap: 8px;
    align-items: center;
}

.ticket_stats span {
    color: #5076FF;
}

/* Tasks footer */
.tasks_container > footer {
    padding-top: 19px;
    position: relative;
    color: rgba(110, 112, 118, 0.72);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.tasks_container > footer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, rgba(232, 235, 240, 0.00) 0%, #E8EBF0 49.5%, rgba(232, 235, 240, 0.00) 100%);
}

/* Copy field */
.copy_field {
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    color: #9E9DA4;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(18, 55, 105, 0.08), 0px 0px 0px 1px rgba(9, 25, 72, 0.13);
    cursor: pointer;
    transition: background .2s;
}
.copy_field_text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.copy_field:hover {
    background: #f7f7f7;
}
.copy_field:active {
    background: #eee;
}
.copy_field span {
    color: #4E75FF;
}
.copy_field img {
    margin-left: auto;
    flex-shrink: 0;
}

/* FAQ */
.faq {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.faq_section {
    overflow: hidden;

    border-radius: 12px;
    border: 1px solid #E8EBF0;
    background: #FFF;

    transition: background .3s, box-shadow .3s;
}
.faq_section_header {
    width: 100%;
    border: none;
    background: #fff;
    display: flex;
    padding: 12px 16px;
    user-select: none;
    cursor: pointer;
    transition: background .3s;
    outline: none;
}
.faq_section_header:not(.open):hover {
    background: #f7f7f7;
}
.faq_section_header.open .faq_arrow {
    transform: rotate(0deg);
}
.faq_arrow {
    flex-shrink: 0;
    margin: 4px 0 0 auto;
    transition: transform .2s;
    transform: rotate(180deg);
    user-select: none;
}
.faq_section:has(.faq_section_header.open) {
    box-shadow: -2px 6px 15px 0px rgba(225, 239, 254, 0.10);
}
.faq_content_wrapper {
    transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}
.faq_title {
    color: #36394A;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}
/* .faq_section_header.open .faq_title {
    font-weight: 500;
} */
.faq_content {
    padding: 0 16px 12px;

    color: #6E7076;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.share_button {
    text-decoration: none;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    height: 40px;

    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

    transition: opacity .3s;
}
.share_button:hover {
    opacity: .8;
}
.share_button.vk,
.share_button.vk:hover {
    color: #fff;
    border: 1px solid #FFF;
    border-radius: 6px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), linear-gradient(180deg, #4680C2 0%, #3774BB 100%);
    box-shadow: none;
}
.share_button.tg,
.share_button.tg:hover {
    color: #fff;
    border: 1px solid #FFF;
    border-radius: 6px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), linear-gradient(180deg, #37BBFE 0%, #1BB2FF 100%);
    box-shadow: none;
}

@media (max-width: 1000px) {
    .giveaway_container {
        padding-right: 8px;
        padding-left: 8px;
    }
    .content_bg {
        border: none !important;
        margin: 0 !important;
        border-radius: 0 !important;
    }
    .task_header > header {
        flex-direction: column;
        gap: 12px;
    }
    .task_ticket {
        margin: 0 auto 0 0;
        order: 1;
    }
    .task_heading {
        order: 2
    }
    .tasks_container.tasks_container__tasks {
        padding: 8px;
    }
    .await_container.tasks_container {
        padding: 16px;
        margin-bottom: 48px;
    }
    .await_container.tasks_container > h2 {
        margin-top: 0;
    }
    .await_container.tasks_container > footer {
        padding: 15px 0 0;
    }
    .tickets {
        flex-direction: column;
        gap: 20px;
        padding: 0 16px;
        margin-bottom: 2px;
    }
    .tickets .join_button {
        margin: 0;
        max-width: none;
    }
    .ticket_stats {
        align-items: center;
    }
    .tasks_container > footer {
        padding: 20px 20px 11px;
    }
}
@media (max-width: 500px) {
    .await_invited_users {
        display: none;
    }
    .task_share {
        flex-direction: column;
    }
}
@media (max-width: 400px) {
    .await_share_buttons {
        flex-direction: column;
    }
    .await_share_buttons:not(.await_share_buttons__column) > * {
        flex: none;
    }
}