.tabs {
    display: flex;
    height: 40px;
    padding: 4px;
    align-items: flex-start;
    gap: 4px;
    flex-shrink: 0;
    align-self: stretch;

    border-radius: var(--radius-8, 8px);
    background: #F7F7F7;
}
.tab {
    display: flex;
    height: 32px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;

    color: #9E9DA4;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
}
.active {
    color: #36394a;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(164, 172, 185, 0.16);
}
.tab_content {
    padding: 40px 15px 16px;
}