.dialog {
    --animation-duration: .3s;
    display: none;
    margin: auto;
    padding: 0 10px;
    gap: 16px;
    inset: 0;
    z-index: 999;
    max-width: 400px;
    width: 100%;
    overflow: hidden;
    border: none;
    background: transparent;
    transition: opacity var(--animation-duration);
}
.dialog > div > h3 {
    margin-bottom: 24px;
    color: #36394A;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height : 28px; /* 140% */
}
.dialog > div {
    flex-grow: 1;
    position: relative;
    padding: 20px;
    background: #FFF;

    border-radius: 16px;
    border: 1px solid #E8EBF0;
    box-shadow: 0px 16px 32px -12px rgba(14, 18, 27, 0.02);
}

.dialog .close_button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;

    border-radius: var(--radius-8, 8px);
    border: 0.75px solid #E8EBF0;
    background: #FFF;
    box-shadow: 0px 16px 32px -12px rgba(14, 18, 27, 0.02);
    cursor: pointer;
    transition: background .3s;
}
.dialog .close_button:hover {
    background: #eee;
}
.dialog[open] {
    display: flex;
    animation: slide-in-up var(--animation-duration) forwards;
}
.dialog[open]::backdrop {
    animation: backdrop-fade var(--animation-duration) forwards;
}
.dialog.closing {
    animation: scale-down var(--animation-duration) forwards;
    opacity: 0;
}
.dialog.closing::backdrop {
    animation: backdrop-fade-out var(--animation-duration) forwards;
}

.description {
    color: #9e9da4;
    margin-bottom: 24px;
}

.dialog.w500 {
    max-width: calc(488px + 16px + 48px + 20px);
    width: 100%;
}

.dialog:not(.closeable).w500 {
    max-width: calc(488px + 20px);
    width: 100%;
}

@keyframes backdrop-fade-out {
    from {
        background: rgba(0,0,0,.24);
    }
    to {
        background: transparent;
    }
}
@keyframes backdrop-fade {
    from {
        background: transparent;
    }
    to {
        background: rgba(0,0,0,.24);
    }
}
@keyframes slide-in-up {
    0% {
        transform:translateY(30%);
    }
}
@keyframes scale-down {
    to {
        transform:translateY(30%);
    }
}