.logo {
    cursor: pointer;
    transition: box-shadow .3s;
    border-radius: 100%;
    display: block;
}
.logo:hover {
    box-shadow: 0 0 20px #3285e5a6;
}

.jumbotron {
    position: relative;
    max-width: 1256px;
    margin: 16px 0 56px;
    padding: 32px 64px;
    height: 628px;
    width: 100%;
    border-radius: 24px;
    background-image: url('../../assets/landing/jumbotron-bg-left.png'), url('../../assets/landing/jumbotron-bg-right.png'), url('../../assets/landing/jumbotron-bg.svg'), radial-gradient(83.13% 101.99% at 40.29% 0%, #98E6FF 0%, #006EEF 100%);
    background-size: auto, auto, cover, auto;
    background-repeat: no-repeat, no-repeat, no-repeat, repeat;
    background-position: bottom left, bottom right, 0 0, 0 0;
    overflow: hidden;
    box-shadow: 0 0 0 1px inset #006eef1f;
}
.jumbotron::after {
    content: "";
    position: absolute;
    inset: 0;
    top: auto;
    height: 247px;
    background: radial-gradient(140.42% 115.52% at 50% -15.52%, rgba(68, 158, 243, 0.00) 48.77%, rgba(50, 133, 229, 0.46) 100%);
}
.jumbotron__header {
    margin-bottom: 43px;
    display: flex;
    justify-content: space-between;
}
.jumbotron__cta {
    position: relative;
    z-index: 10;
    text-align: center;
}
.jumbotron__cta h1 {
    font-family: var(--landing-heading-font-family);
    font-weight: normal;
    font-style: normal;
    color: #0b141f;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 16px;
}
.jumbotron__cta h2 {
    color: #1D2B38;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 36px;
}
.button_cta {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1e2732;
    height: 44px;
    border: none;
    box-shadow: 0px 0px 0px 2px #12376914, 0px 1px 2px 0px #4450653D;
    padding: 0 32px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    border-radius: 48px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(133, 191, 255, 0.36) 100%), rgba(255, 255, 255, 0.96);
    box-shadow: 0px 1px 2px 0px rgba(68, 80, 101, 0.24), 0px 0px 0px 2px rgba(18, 55, 105, 0.08);
    backdrop-filter: blur(16.25px);
    transition: box-shadow .3s;
}
.button_cta:hover {
    color: #1e2732;
    box-shadow: 0px 1px 2px 0px rgba(68, 80, 101, 0.24), 0px 0px 0px 2px rgba(18, 55, 105, 0.08), 0 0 20px #3285e5;
}


.last_purchase {
    position: relative;
    margin: 130px auto 0;
    user-select: none;
    display: flex;
    max-width: 958px;
    width: 100%;
    padding: 13px 27px 13px 27px;
    align-items: flex-start;
    gap: 91px;
    /* overflow: hidden; */

    border-radius: 13.433px;
    background: #FFF;
    box-shadow: 0px 474px 133px 0px rgba(27, 125, 231, 0.00), 0px 303px 121px 0px rgba(27, 125, 231, 0.01), 0px 171px 102px 0px rgba(27, 125, 231, 0.05), 0px 76px 76px 0px rgba(27, 125, 231, 0.09), 0px 19px 42px 0px rgba(27, 125, 231, 0.10);
    backdrop-filter: blur(32px);
}
.last_purchase::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50px;
    right: 50px;
    height: 13px;

    border-radius: 0 0 12px 12px;
    background: rgba(255, 255, 255, 0.57);
    box-shadow: 0px 474px 133px 0px rgba(27, 125, 231, 0.00), 0px 303px 121px 0px rgba(27, 125, 231, 0.01), 0px 171px 102px 0px rgba(27, 125, 231, 0.05), 0px 76px 76px 0px rgba(27, 125, 231, 0.09), 0px 19px 42px 0px rgba(27, 125, 231, 0.10);
    backdrop-filter: blur(16px);
}
.last_purchase::after {
    content: "";
    position: absolute;
    top: calc(100% + 13px);
    left: 144px;
    right: 144px;
    height: 13px;

    border-radius: 0 0 7.606px 7.606px;
    background: rgba(255, 255, 255, 0.35);
    box-shadow: 0px 474px 133px 0px rgba(27, 125, 231, 0.00), 0px 303px 121px 0px rgba(27, 125, 231, 0.01), 0px 171px 102px 0px rgba(27, 125, 231, 0.05), 0px 76px 76px 0px rgba(27, 125, 231, 0.09), 0px 19px 42px 0px rgba(27, 125, 231, 0.10);
    backdrop-filter: blur(10.125115394592285px);
}
.last_purchase__name {
    color: #36394A;
    font-size: 17.91px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.866px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 1px;
}
.user_card {
    display: flex;
    gap: 6px;
}
.user_card__rating {
    display: flex;
    padding: 3.239px 4.478px 2.239px;
    justify-content: center;
    align-items: center;
    gap: 2.239px;
    color: #1db462;
    font-weight: 600;
    font-size: 13.433px;
    line-height: 20.149px;
    border-radius: 7px;
    background: rgba(29, 180, 98, 0.08);
}
.user_card__price {
    margin-left: auto;
}
.user_card__price__value {
    padding-top: 2px;
    color: #36394A;
    text-align: right;
    font-size: 17.91px;
    font-style: normal;
    font-weight: 600;
    line-height: 24.866px;
    white-space: nowrap;
}
.user_card__price__icons {
    padding-top: 4px;
    display: flex;
    gap: 4.478px;
    justify-content: flex-end;
}
.user_card__content {
    display: flex;
    gap: 1px;
    flex-direction: column;
}
.user_card__content p {
    color: var(--text-secondary);
    font-size: 13.433px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.149px;
    white-space: nowrap;
}
.user_card__username {
    padding-top: 1px;
    display: flex;
    gap: 5px;
    align-items: center;
}
.user_card__username b {
    color: #36394A;
    font-size: 17.91px;
    font-style: normal;
    font-weight: 500;
    line-height: 26.866px;
}

.socials_circle {
    display: flex;
    gap: 8px;
}
.socials_circle a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #ffffff80;
    background: #ffffffc7;
    border-radius: 40px;
    background-blend-mode: soft-light, normal;
    transition: box-shadow .3s;
}
.socials_circle a:hover {
    box-shadow: 0 0 20px #3285e5;
}
.social_tg img {
    margin-left: -2px;
}

@media (max-width: 1000px) {
    .last_purchase {
        gap: 25px;
    }
    .jumbotron {
        width: calc(100% - 8px);
        margin: 8px 4px 24px;
        padding: 24px 20px;
        height: 540px;
        background-image: url('../../assets/landing/jumbotron-bg-left.png'), url('../../assets/landing/jumbotron-bg-right.png'), url('../../assets/landing/jumbotron-bg.svg'), radial-gradient(83.13% 101.99% at 40.29% 0%, #98E6FF 0%, #006EEF 100%);
        background-size: auto, auto, cover, auto;
        background-repeat: no-repeat, no-repeat, no-repeat, repeat;
        background-position: 0 calc(100% + 140px), 100% calc(100% + 135px), 0 0, 0 0;
    }
    .jumbotron__cta h1 {
        font-size: 32px;
        line-height: 40px;
    }
    .jumbotron__cta h2 {
        margin-bottom: 24px;
    }
    .last_purchase {
        display: none;
    }
    .last_purchase::before,
    .last_purchase::after {
        display: none;
    }
    .user_card__price {
        margin-left: 0;
    }
    .user_card__price__icons {
        justify-content: flex-start;
    }
}