.header {
    display: flex;
    justify-content: center;
    padding: 0 var(--body-padding);
    background: #fff;
    border-bottom: 1px solid var(--global-border-color);
}

.inner {
    height: 56px;
    max-width: var(--layout-width);
    width: 100%;
    display: flex;
    flex-grow: 1;
    min-width: 0;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 0 var(--content-padding);
}

.user {
    position: relative;
    --animation-duration: .2s;
}
.user_toggle {
    height: 32px;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    user-select: none;
}
.user_avatar {
    border-radius: 100%;
    transition: box-shadow .2s;
}
.user_toggle:hover .user_avatar {
    box-shadow: 0 0 10px #3285e53d;
}
.user_arrow {
    transform: rotate(180deg);
    transition: transform var(--animation-duration);
}
.user_menu {
    opacity: 0;
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    padding: 4px 8px;

    width: 232px;

    border-radius: 8px;
    border: 1px solid #E8EBF0;
    background: #FFF;

    box-shadow: 0px 16px 32px -12px rgba(14, 18, 27, 0.10);
    pointer-events: none;
    transition: opacity var(--animation-duration), height var(--animation-duration);
    animation: slide-down var(--animation-duration) forwards;
}
.user.open .user_arrow {
    transform: rotate(0deg);
}
.user.open .user_menu {
    animation: slide-up var(--animation-duration) forwards;
    pointer-events: all;
    opacity: 1;
}
@keyframes slide-up {
    0% {
        transform:translateY(10%);
    }
}
@keyframes slide-down {
    to {
        transform:translateY(10%);
    }
}

.user_card {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
    margin-bottom: 4px;
    cursor: pointer;
    user-select: none;
    transition: background .3s;
    border-radius: 8px;
}
.user_card:hover {
    background: #f7f7f7;
}
.user_card > div {
    overflow: hidden;
}
.user_card h5 {
    margin-bottom: 2px;

    color: #36394A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    text-overflow: ellipsis;
    overflow: hidden;
}
.user_card a {
    color: #6E7076;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration: none;
}
.user_menu_items {
    border-top: 1px solid #E8EBF0;
    padding-top: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.user_menu_item {
    display: flex;
    gap: 8px;
    height: 36px;
    align-items: center;
    cursor: pointer;
    padding: 0 8px;
    text-decoration: none;
    color: #FF5C5C;
    border-radius: 8px;
    transition: background .3s;
}
.user_menu_item:hover {
    background: #f7f7f7;
    color: #FF5C5C;
}

@media (max-width: 1000px) {
    .header {
        padding: 0;
    }
    .inner {
        height: 48px;
        gap: 24px;
        padding: 0 8px 0 11px;
    }
    .user_menu {
        top: calc(100% + 15px);
    }
}