.field label {
    margin-bottom: 4px;

    display: block;
    color: var(--text-secondary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.hint {
    margin-top: 8px;
    color: #9E9DA4;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.hint_action {
    text-align: right;
}
.hint_action a {
    font-weight: 500;
    text-decoration: none;
}

.input {
    display: flex;
    padding: 7px 10px 7px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    width: 100%;

    border-radius: 8px;
    border: 1px solid transparent;
    background: #FFF;

    box-shadow: 0px 1px 2px 0px rgba(18, 55, 105, 0.08), 0px 0px 0px 1px rgba(9, 25, 72, 0.13);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    font-family: var(--font-family);

    transition: border-color .3s, box-shadow .3s;
}
.input::placeholder {
    color: #9E9DA4;
}
.input:focus {
    outline: none;
    border-color: #4E75FF;
    box-shadow: 0px 1px 2px 0px rgba(78, 117, 255, 0.16), 0px 0px 0px 1px rgba(78, 117, 255, 0.08);
}
.input:user-invalid {
    box-shadow: 0px 1px 2px 0px rgba(255, 92, 92, 0.08), 0px 0px 0px 1px #FF5C5C;
}
.input:user-invalid:focus {
    border: 1px solid transparent;
}
.input_wrapper {
    position: relative;
}

/* Password */
.type_password .input {
    padding-right: 40px;
}
.type_password .input_wrapper img {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transition: opacity .3s;
}
.type_password .input_wrapper img:hover {
    opacity: .5;
}

/* Sizes */
.input.large {
    height: 65px;

    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
}