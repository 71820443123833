body {
    font-family: var(--font-family);
    font-size: 14px;
    font-optical-sizing: auto;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --landing-body-padding: 10px;
    --font-family: 'Inter', sans-serif;
    --landing-heading-font-family: 'Roobert', 'Inter', sans-serif;

    --body-padding: 24px;
    --content-padding: 64px;
    --layout-width: 1256px;
    --global-border-color: #E8EBF0;
    --global-bg: #FFF;
    --text-secondary: #6e7076;
}

@font-face {
    font-family: 'Roobert';
    src: url('./assets/fonts/RoobertTRIAL-SemiBold.woff2') format('woff2');
    font-display: swap;
    font-weight: normal;
    font-style: normal;
}

a {
    color: #5C80FD;
    transition: color .3s;
}
a:hover {
    color: #274fdc;
}
button, input {
    font-family: var(--font-family);
}