.container {
    width: 100%;
    background: #F7F7F7;

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
}
.container > main {
    display: flex;
    flex: 1;
    padding: 0 8px;
}

.container_landing {
    max-width: calc(1256px + var(--landing-body-padding) * 2);
    width: 100%;

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0 var(--landing-body-padding);
}

.content_wide {
    max-width: 1256px;
    width: 100%;

    border-radius: 16px;
    border: 1px solid var(--global-border-color);
    background: #FFF url('../../assets/bg-radial.svg') bottom center no-repeat;
    box-shadow: 0px 16px 32px -12px rgba(14, 18, 27, 0.02);
    margin: 24px auto;
}


@media (max-width: 1000px) {
    .container_landing {
        padding: 0;
    }
    .layout_giveaway > main {
        padding: 0;
        border-radius: 0;
    }
}
