.field {
    position: relative;
    display: flex;
    gap: 8px;

    color: var(--text-secondary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
}
.hint {
    margin-top: 8px;
    color: #9E9DA4;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.field input {
    position: absolute;
    opacity: 0;
    z-index: 1;
}
.box {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background: #E1E4EA;
    transition: background .2s;
}
.box > div {
    position: relative;
    width: 12px;
    height: 12px;
    box-shadow: 0px 2px 2px 0px #1B1C1D1F;
    background: #FFFFFF;
    border-radius: 2.6px;
    transition: background .2s;
}
.box > div::before,
.box > div::after {
    content: "";
    position: absolute;
    left: 4px;
    bottom: 4px;
    background: #fff;
    width: 2px;
    transform-origin: bottom center;
    transform: rotate(45deg);
}
.box > div::before {
    height: 7px;
    transform: rotate(45deg);
}
.box > div::after {
    height: 4px;
    transform: rotate(-45deg);
}

/* Unchecked */
.field input + .box:hover {
    background: #cacfd8;
}

.field input:focus-visible + .box {
    background: #335CFF;
}

.field input:disabled + .box,
.field input:disabled + .box > div {
    background: #E1E4EA;
    box-shadow: none;
}

/* Checked */
.field input:checked + .box,
.field input:checked + .box > div {
    background: #335CFF;
}

.field input:checked + .box:hover,
.field input:checked + .box:hover > div {
    background: #2547D0;
}

.field input:checked:focus-visible + .box,
.field input:checked:focus-visible + .box > div {
    background: #1F3BAD;
}

.field input:checked:disabled + .box,
.field input:checked:disabled + .box > div {
    background: #E1E4EA;
    box-shadow: none;
}