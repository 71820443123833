.footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #E8EBF0;
    color: var(--text-secondary);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 12px 0;
    margin: 0 auto;
    max-width: 1128px;
    width: 100%;
}

@media (max-width: 1000px) {
    .footer {
        padding: 16px;
        background: #fff;
    }
}
@media (max-width: 390px) {
    .footer {
        flex-direction: column;
    }
}