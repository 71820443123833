.error_container {
    margin: 146px auto 0;
}
.error_container p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--text-secondary);
}
.error_container p a {
    font-weight: 500;
    text-decoration: none;
}

.logo404wrapper {
    display: flex;
    justify-content: center;
}
.logo404 {
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 9px;
    margin-bottom: 48px;
    transition: transform .3s;
}
.logo404 img {
    display: block;
}
.logo404:hover {
    transform: scale(0.95);
}