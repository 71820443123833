.content {
    padding: 40px 64px;
}
.content > h2 {
    margin-bottom: 8px;

    color: #36394A;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
}
.text {
    color: #6E7076;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.text hr {
    border: none;
    border-bottom: 1px solid #E8EBF0;
    margin: 24px 0;
}