:root {
    --toastify-toast-width: 398px;
}

.Toastify__toast-theme--starvell {
    font-family: var(--font-family);
    padding: 16px;

    box-shadow: 0px 16px 32px -12px #0E121B05;
    border: 1px solid #E8EBF0;
    background: #fff;
    user-select: none;
}
.Toastify__toast-theme--starvell .Toastify__toast-body {
    align-items: flex-start;
    padding: 0;
}
.Toastify__toast-theme--starvell .Toastify__toast-icon {
    margin: 3px 4px;
}

.toast__title {
    margin-bottom: 4px;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #36394A;
}
.toast__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-secondary);
}
.toast__close {
    transition: opacity .3s;
}
.toast__close:hover {
    opacity: .5;
}