.rules_content {
    color: #36394a;
    padding: 40px 64px;
}
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
}
.block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}
.block_split {
    display: flex;
    width: 100%;
    gap: 24px;
    justify-content: space-between;
}
.block_left {
    width: 100%;
}
.block_right {
    width: 488px;
    min-width: 488px;
    align-items: flex-start;
}
.block_half {
    display: flex;
    flex-direction: column;

}
.block_split.bordered {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--global-border-color);
}
.block_half p {
color: var(--starvell-text-muted);
font-size: 14px;
line-height: 20px;
}
.block_half p:not(.block_half p:last-child){
    padding-bottom: 12px;
    border-bottom: 1px solid var(--global-border-color);
}
.block h5 {
    color: var(--starvell-text-muted);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
}
.block span {

    color: var(--text-secondary);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
    font-weight: 500;
}
.bottom {
    padding-top: 16px;
    margin-top: 40px;
    border-top: 1px solid var(--global-border-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
}
.rules_bottom {
    width: 100%;
    display: flex;
    justify-content: left;
}
.category:not(.category:nth-child(2)){
    margin-top: 16px;
}
.category {

    padding-bottom: 8px;
    width: 100%;
    font-size: 16px!important;
    line-height: 24px!important;
    font-weight: 500;
}
.bottom span {
    margin-bottom: 0;
    color: var(--text-secondary);
    font-size: 14px;
    line-height: 20px;
}
.title {
    color: var(--starvell-text-muted);
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
}
.subtitle {
    color: var(--starvell-text-muted);
    font-size: 20px;
    display: flex;
    gap: 4px;
    align-items: center;
    line-height: 28px;
    font-weight: 600;
}