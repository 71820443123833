.button {
    --button-pl: 12px;
    --button-pt: 4px;
    position: relative;
    display: flex;
    height: 40px;
    padding: var(--button-pt) var(--button-pl);
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;

    border-radius: 8px;
    border: none;
    border-top: 1px solid transparent;
    border-bottom: 2px solid transparent;

    color: #36394A;
    text-align: center;
    font-family: var(--font-family);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    transition: border-color .3s, background .3s;

    cursor: pointer;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), #FFF;
    box-shadow: 0px 1px 2px 0px rgba(164, 172, 185, 0.24), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
}
.button:not(:disabled):hover {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), #FAFAFA;
}
.button:not(:disabled, .primary):active {
    background: #eee;
}
.button:disabled {
    opacity: 0.4;
    cursor: default;
}

.button.primary {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), #4E75FF;
    box-shadow: 0px 1px 2px 0px rgba(57, 89, 204, 0.50), 0px 0px 0px 1px #4665D2;
    border-top-color: rgba(255, 255, 255, 0.12);
    color: #FFF;
}
.button.primary:not(:disabled):hover {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), #274FDC;
    box-shadow: 0px 1px 2px 0px rgba(57, 89, 204, 0.50), 0px 0px 0px 1px #4665D2;
}

.icon_left img {
    position: absolute;
    left: var(--button-pl);
    top: 50%;
    transform: translateY(-50%);
}

.button.wide {
    width: 100%;
}

.button.bdrs4 {
    border-radius: 4px;
}

.button.bdrs6 {
    border-radius: 6px;
}